<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="borrowCodeNumber" label="借阅单号：">
                <el-input v-model.trim="searchForm.borrowCodeNumber" maxlength="50" placeholder="请输入借阅单号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="borrowType" label="借阅类型：">
                <el-select v-model="searchForm.borrowType" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_type')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowUserNameDisplayed" label="借阅人：">
                <el-select v-model="searchForm.borrowUserNameDisplayed" filterable placeholder="请选择" clearable
                           class="w100i">
                    <el-option v-for="(item, index) in userList" :key="index" :label="item"
                               :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowPurpose" label="借阅目的：">
                <el-select v-model="searchForm.borrowPurpose" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_purpose')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowTime" label="借阅时间：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.borrowTime"
                        type="date"
                        placeholder="请选择借阅时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="deadline" label="期限：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.deadline"
                        type="date"
                        placeholder="请选择期限" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="returnState" label="归还状态：">
                <el-select v-model="searchForm.returnState" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('return_state')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="auditState" label="审核状态：">
                <el-select v-model="searchForm.auditState" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('audit_state').filter(val => val.value != 0)">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="keyWord" label="输入搜索：">
                <el-input v-model.trim="searchForm.keyWord" maxlength="50" placeholder="题名/档号"></el-input>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button size="small" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button size="small" icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <div class="flex_b_c">
                <el-tabs v-model="listType" size="small" @tab-click="search(1)">
                    <el-tab-pane label="按借阅单号" name="1"></el-tab-pane>
                    <el-tab-pane label="按档案" name="2"></el-tab-pane>
                </el-tabs>
                <el-button size="small"
                           v-show="hasPermission('borrow:record:export')"
                           @click="exportData()">
                    <i class="icon-piliangdaochu iconfont buIcon"/>
                    批量导出
                </el-button>
            </div>
            <div class="tableBgBox" v-loading="loading">
                <el-table v-if="listType == 1 && !loading"
                          :data="dataList"
                          v-loading="loading"
                          size="small"
                          @selection-change="selectionChangeHandle"
                          height="calc(100vh - 452px)"
                          class="table" ref="multipleTable" row-key='id'>
                    <el-table-column type="selection" :reserve-selection='true' fixed/>
                    <el-table-column prop="borrowCodeNumber" label="借阅单号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="borrowType" label="借阅类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("borrow_type", scope.row.borrowType ,'')}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="borrowUserNameDisplayed" label="借阅人" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="borrowPurpose" label="借阅目的" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("borrow_purpose", scope.row.borrowPurpose ,'')}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="borrowTime" label="借阅时间" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="deadline" label="期限" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="returnState" label="归还状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("return_state", scope.row.returnState ,'')}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="auditState" label="审核状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("audit_state", scope.row.auditState ,'')}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small"
                                       v-show="hasPermission('borrow:record:view')"
                                       @click="addEdit(scope.row, 'view')">详情
                            </el-button>
                            <el-button type="text" size="small"
                                       v-if="hasPermission('borrow:record:auditLog')"
                                       @click="auditLog(scope.row)">审核记录
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table v-if="listType == 2 && !loading"
                          :data="dataList"
                          v-loading="loading"
                          size="small"
                          @selection-change="selectionChangeHandle"
                          height="calc(100vh - 452px)"
                          class="table" ref="multipleTable" row-key='dataBorrowId'>
                    <el-table-column type="selection" :reserve-selection='true' fixed/>
                    <el-table-column prop="archivesDataName" label="题名" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="reference" label="档号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="borrowType" label="借阅类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("borrow_type", scope.row.borrowType ,'')}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="archivesBasicName" label="档案类型" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="quanzongNumber" label="全宗号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="quanzongName" label="全宗名称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="borrowUserNameDisplayed" label="借阅人" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="createTime" label="申请时间" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="returnState" label="借阅状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("return_state", scope.row.returnState ,'')}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="returnTime" label="归还时间" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed="right" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small"
                                       v-show="hasPermission('borrow:record:view')"
                                       @click="addEdit(scope.row, 'view')">详情
                            </el-button>
                            <el-button type="text" size="small"
                                       v-if="hasPermission('borrow:record:auditLog')"
                                       @click="auditLog(scope.row)">审核记录
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="p_r" v-if="!loading">
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="searchForm.current"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="searchForm.size"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                    <div class="totalInfo">共 <span>{{total}}</span> 条记录，档案申请 <span>{{ listType == '1' ? applyTotal : total}}</span> 次</div>
                </div>
            </div>
        </div>
        <AuditLog ref="auditLog"></AuditLog>
        <SubmitterForm ref="submitterForm"></SubmitterForm>
        <OperatorForm ref="operatorForm"></OperatorForm>
        <LendApplyForm ref="lendApplyForm"></LendApplyForm>
        <InteriorApplyForm ref="interiorApplyForm"></InteriorApplyForm>
    </div>
</template>

<script>
    import AuditLog from '../components/auditLog'
    import SubmitterForm from '../electronic/submitterForm'
    import OperatorForm from '../entity/read/operatorForm'
    import LendApplyForm from '../entity/lend/applyForm'
    import InteriorApplyForm from '../entity/interior/applyForm'

    export default {
        components: {AuditLog, SubmitterForm, OperatorForm, LendApplyForm, InteriorApplyForm},
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                searchForm: {
                    borrowCodeNumber: '',
                    borrowType: '',
                    borrowUserNameDisplayed: '',
                    borrowPurpose: '',
                    borrowTime: '',
                    deadline: '',
                    returnState: '',
                    auditState: '',
                    keyWord: '',
                    current: 1,
                    size: 10,
                },
                listType: '1',
                userList: [],
                loading: false,
                dataList: [],
                dataListSelections: [],
                total: 0,
                applyTotal: 0,
            }
        },
        mounted() {
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                this.getUserList()
                this.getTotal()
                if (type == 1) {
                    this.searchForm.current = 1
                    if(this.$refs.multipleTable) {
                        this.$refs.multipleTable.clearSelection()
                    }
                }
                this.loading = true
                let api = this.api.record.findBorrowRecordByBorrowCodeNumber
                if (this.listType == 2) {
                    api = this.api.record.findBorrowRecordByArchivesData
                }
                this.$axios(api, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            getTotal() {
                this.$axios(this.api.record.findArchiveNumberByBorrowCodeNumber, this.searchForm, 'post').then((res) => {
                    if (res.data && res.status) {
                        this.applyTotal = res.data
                    }
                })
            },
            // 详情
            addEdit(row, method) {
                switch (row.borrowType) {
                    case '0':
                        this.$refs.submitterForm.init(row, method)
                        break;
                    case '1':
                        this.$refs.operatorForm.init(row, method)
                        break;
                    case '2':
                        this.$refs.lendApplyForm.init(row, method)
                        break;
                    case '3':
                        this.$refs.interiorApplyForm.init(row, method)
                        break;
                }
            },
            // 审核记录
            auditLog(row) {
                this.$refs.auditLog.init(row.id, row.borrowType)
            },
            // 导出
            exportData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let idList = this.dataListSelections.map(item => {
                    return this.listType == 2 ? item.dataBorrowId : item.id
                })
                let api = this.api.record.borrowCodeNumberExport
                if (this.listType == 2) {
                    api = this.api.record.borrowArchivesDataExport
                }
                this.exportExcel(api, {idList}, '借阅记录', 'post')
            },
            // 重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 获取用户列表
            getUserList() {
                this.$axios(this.api.record.findUserNameByBorrowCodeNumber, this.searchForm, 'post').then(data => {
                    if (data && data.status) {
                        this.userList = data.data
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }
    }
    .tableBgBox {
        height: calc(100vh - 405px);
    }
    .totalInfo {
        bottom: 7px !important;
    }
</style>
